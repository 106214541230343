<template>
  <span>
    <!-- container_ekmtc -->
    <div class="popup_dim" ref="popup">
      <div class="popup_wrap" style="width:800px; height:520px;">
        <button class="layer_close"
                @click.prevent="closePopup()"
        >
          close
        </button>
        <div class="popup_cont">
          <h1 class="page_title">
            {{ $t('comp.300') }}<!-- 파일첨부 -->
          </h1>
          <div class="content_box">
            <DEXT5Upload
              ref="dextupload"
              :id="component_id"
              :single="single"
              :category="category"
              width="100%"
              height="200px"
              @completed="uploadCompleted"
              :file-info="value"
              :category-info="catList"
            />
          </div>
        </div>
        <div class="mt10 text_center">
          <a class="button blue lg" @click.prevent="closePopup()">{{ $t('msg.MYIN030T010.022') }}</a>
        </div>
      </div>
    </div>

    <button :class="$ekmtcCommon.isEmpty(fileList) ? 'button sm gray file' : 'button sm blue'"
            @click.prevent="openFilePopup(); return false"
    >
      {{ $t('comp.300') }}
    </button>
  </span>
</template>

<script>
export default {
  components: {
    // DEXT5Upload: () => import('@/components/DEXT5Upload')
    DEXT5Upload: () => import('@/components/common/DEXT5UploadForMr')
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    single: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    catList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      fileList: []
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    },
    category () {
      return this.catList.length !== 0
    }
  },
  mounted () {
  },
  methods: {
    uploadCompleted (fileInfo) {
      console.log('fileInfo ::: ', fileInfo)
      this.fileList = fileInfo
      this.$emit('completed', fileInfo)
    },
    openFilePopup () {
      const st = $('html').scrollTop()
      $(this.$refs.popup).data('scroll-top', st).fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePopup: function () {
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()

      const st = $(this.$refs.popup).data('scroll-top')
      $(this.$refs.popup).fadeOut()
      $('body,html').removeAttr('style')
      $('html').scrollTop(st)
      return false
    }
  }
}
</script>
<style>
.DEXT_fiVe_UP_ly_wrapper{
  z-index: 99999999 !important;
}
</style>
